body {
    margin: 0;
    padding: 20px;
    font-family: sans-serif;
    background-color: pink;
}

.active {
    background-color: #0099FF;
}
