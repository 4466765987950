.bio-pic {
    height: 250px;
    width: 250px;
}

.bios-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content:center;
}

.bio {
    margin: 10px;
    position: relative;
    height: 250px;
    border: 1px solid black;
}

.name {
    color: black;
    font-weight: 900;
    font-size: 26px;
    text-align: center;
    line-height: 28px;
    padding-bottom:15px;
}

.name-wrapper a {
    text-decoration:none;
    color:black;
}

.bio:hover .name-wrapper {
    visibility:visible;
    transition: .4s ease;
}

.bio:hover .bio-pic {
    opacity: 0.2;
    transition: ease 0.4s;
}

.bio:hover {
    cursor: pointer;
}

.disable-imagus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.di-wrapper {
    position: relative;
}

.name-wrapper {
    position: absolute;
    top: 42%;
    z-index: 2;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    visibility: hidden;
}

.manager-page-pic {
    border: 1px solid black;
}
