h1 {
    display: block;
    font-size: 2em;
    font-weight:bold;
}

nav {
    background-color: black;
    padding: 0;
}

nav a {
    color: white;
    font-weight: 700;
    padding: 20px;
    display:inline-block;
    text-decoration: none;
}

.content {
    background-color: white;
    padding: 20px;
}